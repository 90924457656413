import { createStore } from "vuex";

import geral from "./geral";
//import user from "./user";
//import monitor from "./monitor";
//import login from "./login";
//import progress from "./user_progress"

const store = createStore({
    modules: {
        geral
    }
})

export default store