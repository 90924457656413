<template>
  <ion-page>
    <ion-tabs v-if="this.mapLoaded" ref="tabRoots">
      
      <ion-router-outlet ></ion-router-outlet>

      <ion-tab-bar slot="bottom">
        
        <ion-tab-button tab="tab5" href="/tabs/reports">
          <ion-icon aria-hidden="true" :icon="dashboard" class="icon" />
          <ion-label>Dashboard</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab4" href="/tabs/contacts">
          <ion-icon aria-hidden="true" :icon="contato" class="icon" />
          <ion-label>Contatos</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab1" href="/tabs/map">
          <ion-icon aria-hidden="true" :icon="mapa" class="icon" />
          <ion-label>Mapa</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" href="/tabs/agenda">
          <ion-icon aria-hidden="true" :icon="agenda" class="icon"/>
          <ion-label>Agenda</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab3" href="/tabs/menu">
          <ion-icon aria-hidden="true" :icon="menu" class="icon" />
          <ion-label>Menu</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
      
    </ion-tabs>
    <!-- <agenda-page></agenda-page> -->
  </ion-page> 
</template>

<script  >


  /* eslint-disable */
  //import agendaPage from './Tab2Page.vue';

  //import { ref } from 'vue';
  import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
  //import { ellipse, square, triangle } from 'ionicons/icons';
  
  //import  * as map   from './../assets/js/map.js';
//const tabRoots = ref(null);

export default {
  components: {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonLabel,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    
  },
  setup() {
    return {
      
      dashboard : require('./../assets/images/dashboard.svg'),
      contato   : require('./../assets/images/contato.svg'),
      mapa      : require('./../assets/images/mapa.svg'),
      agenda    : require('./../assets/images/agenda.svg'),
      menu      : require('./../assets/images/menu.svg'),
    }
  },
  data(){

    return{
       mapLoaded: false
    }
  },

  methods:{

    setLoaded(status){
      this.mapLoaded = status;
    }
  },
  mounted(){
    
    // console.log(this.$refs);
    //const googleMapsScript = document.createElement('SCRIPT');
    let setLoaded = this.setLoaded;
    if(document.getElementById('googleMaps') === null) {

        window.googleMapsScript.setAttribute(
            "src",`https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_API_KEY}&libraries=places`
        );

        window.googleMapsScript.setAttribute('id','googleMaps');
        window.googleMapsScript.setAttribute('defer','');
        window.googleMapsScript.setAttribute('async','');

        document.head.appendChild(window.googleMapsScript);
        
        window.googleMapsScript.onload = function(){
          setLoaded(true);
        };
    }

    // googleMapsScript.onload = function(){
        //   load();
        // };
  }
}
</script>


<style>
.icon {
  font-size: 30px;
}
</style>