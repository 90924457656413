
import jquery from 'jquery';

import { Camera, CameraResultType,CameraSource } from '@capacitor/camera';


export const isInt = function (n){
    
    return  n % 1 === 0;
}

export const isFloat = function(n){
    return  n % 1 !== 0;
}

export const vrToFloat = function(value){
    
    if( typeof value == 'string' && value.match(/,/)){
        return parseFloat(value.replace('.','').replace(',','.'));
    }else{ 
        return parseFloat(value);
    }
    
}


export const  mascara_milhar = function(v,decimal) {
	

    if(v != null){
       // console.log(v);
        v = parseFloat(v.replace(",", "."));
        //console.log(v);
        var texto = v.toFixed(decimal);
        //console.log(texto);
        
        var split = texto.toString();
        split = split.split('.');
    
        var stringComPontos = split[0].replace(/(\d)(?=(?:[0-9]{3})+\b)/g, "$1.")+','+split[1];
        //console.log(stringComPontos);	
        return stringComPontos;
    }else{
        return v;
    }
}

export const findValue = (needle,stack) => { 
    
    return stack.filter(x => {
        
         if (x.name === needle)
             return true
    })
}

export const isArray = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Array]';
}

export const deepClone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

export const getStorage = (persist, key) => {

    if (persist === JSON.stringify(true))
        return localStorage.getItem(key)
    else
        return sessionStorage.getItem(key)
}

export const removeStorage = () => {
    
    localStorage.removeItem("jwt")
    localStorage.removeItem("user")
    localStorage.removeItem("impersonate");
    localStorage.removeItem("impersonatedUser");
    localStorage.removeItem("impersonateId");
    localStorage.removeItem("admin");
    localStorage.removeItem("impersonatePic");

    sessionStorage.removeItem("jwt")
    sessionStorage.removeItem("user")
    
    localStorage.removeItem('user_progress')
}

export const getAccount = (obj,INT_USU) => {
           
    return new Promise((resolve) => {

        obj.$store.dispatch('loadData',{route: '/geral/account/'+INT_USU,  service: 'account'}).then( async (res) => {

            if(res.type == 'success'){
    
                var account = await obj.$store.getters.getLoadedData('account');
                resolve({ type: "success", data: account[0], obj:obj });
    
            }else{
                resolve({ type: "error", response: res });
            }
    
        });

    });

}

export const getUser = (obj) => {

    return new Promise((resolve) => {
          
        obj.$store.dispatch('loadData',{route: '/geral/me', service: 'user'}).then( async (res) => {
            //console.log(res);
            
            if(res.type == 'success'){

                var user = await obj.$store.getters.getLoadedData('user');
                //console.log(user);
                resolve({ type: "success", data: user, obj:obj });
    
            }else{ 
             
                resolve({ type: "error", response: res });
            }
    
        });
       
    });

    
}


export const getOwner = (obj) => {

    return new Promise((resolve) => { 
        
        var owner = {
            avatar : 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/gym/coach-man.png'
        };

        getUser(obj).then((u) => {

            if(u.type == 'success'){ 

                owner.text    = u.data.user.NM_LGN;
                owner.id      = u.data.user.INT_USU;
                owner.admin   = u.data.user.LG_SUPER;
                owner.INT_USU = u.data.account.INT_USU;

                owner.INT_MUN = u.data.account.INT_MUN;
                owner.INT_EST = u.data.account.INT_EST;
                owner.INT_CTA = u.data.account.INT_CTA;

                resolve({owner:owner,type:u.type});
            }else{
                resolve(u);
            }
            
            // getAccount(obj,u.data.INT_USU).then( (a) => {

            //     owner.INT_CTA = a.data.INT_CTA;
            //     resolve({owner:owner});
   
            // });


         });

        
        
        // var account = JSON.parse(localStorage.getItem('account'));
        // var user    = JSON.parse(localStorage.getItem('user'));
      
        // var owner = {

        //   text    : user.NM_LGN,
        //   id      : user.INT_USU,
        //   INT_MUN : user.INT_MUN,
        //   INT_EST : user.INT_EST,
        //   account : account.INT_CTA,
        //   //  color  : '#5568b0',
        //   avatar : 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/gym/coach-man.png'
        // }

        // resolve({owner:owner});
    });
}

const findAddress =  (item) =>{
    
    
    if(item != undefined){
         
       var index =  item.find(  (x) => {
                    var find =  x.address_components.find( (y) => {  return y.types.includes('route') || y.types.includes("establishment") });
                    return (find != undefined);
                })
    }

    //console.log(index);
    
    return index;


}

const getItemAdress = (address,type) => {

    return  address.address_components.find( (x) => {  return x.types.includes(type) });
}

export const filterAddress = async (data) => {
    
    var address  = await findAddress(data);
    
    var STR_LOGR = await getItemAdress(address,'route');
    
    if(STR_LOGR == undefined){
        STR_LOGR = await getItemAdress(address,'establishment');
    }
    
    var STR_BAIR = await getItemAdress(address,'sublocality_level_1');
    var STR_PAIS = await getItemAdress(address,'country');
    var CEP_END  = await getItemAdress(address,'postal_code');
    var state    = await getItemAdress(address,'administrative_area_level_1');
    var city     = await getItemAdress(address,'administrative_area_level_2');
    
    return new Promise((resolve) => { 

        address['STR_LOGR'] = (STR_LOGR != undefined) ? STR_LOGR.short_name : '';
        address['STR_BAIR'] = (STR_BAIR != undefined) ? STR_BAIR.short_name   : '';

        address['STR_PAIS'] = (STR_PAIS != undefined) ? STR_PAIS.long_name  : '';
        address['CEP_END']  = (CEP_END != undefined)  ? CEP_END.short_name : '';

        address['state']   = (state != undefined) ? state.short_name : '';
        address['city']    = (city  != undefined) ? city.short_name : '';

        address['lat']   = (address.geometry != undefined) ? address.geometry.location.lat : '';
        address['lng']   = (address.geometry != undefined) ? address.geometry.location.lng : '';

        resolve({address:address});
    });
   
   
}

export const loadAddressById = (place_id) => {

    return new Promise((resolve) => { 

        var path = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${place_id}&key=${process.env.VUE_APP_API_KEY}`;
        
        jquery.get( path, async function( data ) {
                resolve({address:data.results});
        });

    });
    
}


export const loadAddress = (latlng) => {

    return new Promise((resolve) => { 

        var path = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng.lat()},${latlng.lng()}&key=${process.env.VUE_APP_API_KEY}`;
      
        jquery.get( path, async function( data ) {
                resolve({address:data.results,place_id:data.place_id});
        });

    });

}


export const takePhoto = async () => {

    const photo = await Camera.getPhoto({
        resultType : CameraResultType.Base64,
        source : CameraSource.camera,
        quality: 60,

    });
    
    //this.url = "data:image/png;base64,"+photo.base64String;

    var basephoto = await getBlob(photo.base64String) ;

    var reader = new FileReader();
    
    //let setUrl = this.setUrl;
    reader.readAsDataURL(basephoto); 

    return reader;
    // reader.onloadend = function() {
    //     setUrl("data:image/png;"+reader.result);   
    // }
}


export const getBlob = async (b64Data, contentType, sliceSize) => {

    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, {type: contentType});
    return blob;

}