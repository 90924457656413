//import { cpf, cnpj }       from 'cpf-cnpj-validator';
//import { getAddressByCEP } from 'cep-address-finder';
import { vrToFloat}    from './common-usage.js';
import jquery from 'jquery';

export let states = [
    'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG',
    'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
];



export const validate_info_output = (data) => {
   
    if( Object.prototype.toString.call(data) === '[object Object]' ){
        
        for (let [key, value] of Object.entries(data)) {
            
            if( Object.prototype.toString.call(value) === '[object Array]' ){
                data[key] = validate_info_input(value);
            }else{
                //put the validations here
                data = validOutputDate(key,data)
                data = validOutputCurrency(key,data)
            }
        }
        
    }else if( Object.prototype.toString.call(data) === '[object Array]' ){
        
        data.forEach(element => {
            validate_info_output(element);
        });

    }

    return data;
}

export const validate_info_input = (data) => {
    
    if( Object.prototype.toString.call(data) === '[object Object]' ){
        
        for (let [key, value] of Object.entries(data)) {
            
            if( Object.prototype.toString.call(value) === '[object Array]' ){
                data[key] = validate_info_input(value);
            }else{ 
                //put the validations here
                data = validInputDate(key,data)
                data = validInputCurrency(key,data)
            }
        }
        
    }else if( Object.prototype.toString.call(data) === '[object Array]' ){
        
        data.forEach(element => {
            validate_info_input(element);
        });

    }

    return data;
}

export const UsDateTimeToPtDateTime = (usDateTime) => {

    var dateTime =  new Date(usDateTime) ;

    var ptDate = dateTime.toLocaleDateString('pt-BR');
    var ptTime = dateTime.toLocaleTimeString('pt-BR');
    
    return ptDate+' '+ptTime;
}

export const UsDateTimeToPtDate = (usDateTime) => {

    var nDate = usDateTime.split(' ');
    
    return nDate[0];
}

export const PtDateTimeToUsDateTime = (brDateTime) => {
    
    brDateTime = brDateTime.split(' ');
    let usDate = brDateTime[0].split('/').reverse().join('-'); 
    
    var newDataTime = usDate+' '+brDateTime[1];
   
    return newDataTime;
}

export const getDateTime = () => {


    var date = new Date().toISOString().substr(0, 19);

    return UsDateTimeToPtDateTime(date);
    
}

export const getYear = () => {

    return new Date().getFullYear();

}

function validInputDate(field, data){

    var regex = new RegExp(/^\d+?\/\d+\/.+\d$|\d+?-\d+-.+\d$/);
    var ptBr  = new RegExp(/\//)    
    
    
    if(regex.test(data[field])){
       
        if(data[field].indexOf(":") < 0 ){
            data[field] = data[field] + ' 00:00:00' ;   
        }
            
        if (ptBr.test(data[field])){ 
            data[field] = PtDateTimeToUsDateTime(data[field]);   
        }
        
    }else if(data[field] == ''){
        data[field] = null
    }

    return data;
}

function validOutputDate(field, data){

    //var regex = new RegExp(/^DH_/);
        
    //if(regex.test(field)){
            
        if (data[field] != null && data[field].date != undefined ){
            data[field] = UsDateTimeToPtDate(data[field].date);   
        }
        
        // else{
        //     data[field] = UsDateTimeToPtDateTime(data[field]);   
        // }
    //}

    return data;
}

function validInputCurrency(field, data){

    var regex = new RegExp(/^VR_|^QTD_/);
        
    if(regex.test(field)){
            
        data[field] = vrToFloat(data[field]);
              
    }

    return data;
}

function validOutputCurrency(field, data){
    
    var regex = new RegExp(/^VR_|^QTD_/);
        
    if(regex.test(field)){
        console.log(data);
        //data[field] = data[field]; //mascara_milhar(data[field],4);
    }

    return data;
}

/* Forms Validations */
// function validCPF(value) {
//     if(value == "") return true;
//     return cpf.isValid(value);
// }

// function validCNPJ(value) {
//     if(value == "") return true;
//     return cnpj.isValid(value)
// }

function validCEP(value) {
    return value
}

function validEmail(value) {
    if(value == "") return true;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
}

function validPhone(phone) {
    //var telefone = '(31)9923-99288';
    var regex = new RegExp('^\\(((1[1-9])|([2-9][0-9]))\\)((3[0-9]{3}-[0-9]{4})|(9[0-9]{3}-[0-9]{5}))$');
    return regex.test(phone);
}


const validate = (value, field) => {
    let isValid, msg = ""
    switch (field) {
        
        case "telefone":
            isValid = validPhone(value)
            console.log("telefone isValid: ", isValid)
            if (!isValid)
                msg = "Telefone Inválido"
            break
        case "whatsapp":
            isValid = true
            console.log("isValid: ", isValid)
            if (!isValid)
                msg = "Telefone Inválido"
            break
        case "cep":
            isValid = validCEP(value)
            console.log("cwp isValid: ", isValid)
            if (!isValid)
                msg = "CEP Inválido"
            break
        case "sameEmails":
            console.log("emails valid: ", value)
            isValid = value
            if (!isValid)
                msg = "Os e-mails devem ser iguais!"
            break
        case "email":
            isValid = validEmail(value)
            console.log("email isValid: ", isValid)
            if (!isValid)
                msg = "E-mail Inválido"
            break
    }

    return { sig: isValid, log: msg }

}


function validForm(form_data) {
    let isValid = true, validation = { sig: false, msg: "" }, val

    for (let [field, obj]  of Object.entries(form_data)) {
        let selector = jquery("." + obj.selector)
        val = validate(obj.value, field)
        console.log("sig:", val.sig)
        console.log("isValid: ", isValid)
        if (val.sig) {
            selector.removeClass("is-invalid")
            selector.addClass("is-valid")
        }else {
            selector.removeClass("is-valid")
            selector.addClass("is-invalid")
            isValid = false
        }

        if (val.log !== "")
            validation.msg = val.log
    }

    return { sig: isValid, log: validation.msg }
}

// async function fillByCep(cep) {
//     const address = await getAddressByCEP(cep)
//     return {
//         cidade: address.city, bairro: address.neighborhood,
//         estado: address.state, endereco: address.street
//     }
// }

export { validForm }