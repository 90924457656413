<template>
    
    
    <ion-page  >
        
        
        <slot name="area-menu"></slot>

        <ion-header v-if="header">
           

                <slot name="baselayout-header">
                    <ion-toolbar>
                        <ion-buttons slot="start">
                            <ion-back-button :default-href="pageDefaultBack"></ion-back-button>
                        </ion-buttons>
                        
                        <ion-title> {{ pageTitle }}</ion-title>

                        <ion-buttons slot="end" >
                            <slot name="actions-end"></slot>
                        </ion-buttons>
                    </ion-toolbar>
                </slot>
                       
           
        </ion-header>

        <ion-content v-if="fixed" >
            <ion-grid :fixed="fixed"> 
                <ion-refresher v-if="this.refresh" slot="fixed" @ionRefresh="handleRefresh($event)">
                 <ion-refresher-content></ion-refresher-content>
                </ion-refresher>
                <slot  />   
            </ion-grid>
            
        </ion-content>

        <ion-content v-else >
                <ion-refresher v-if="this.refresh" slot="fixed" @ionRefresh="handleRefresh($event)">
                 <ion-refresher-content></ion-refresher-content>
                </ion-refresher>
                <slot  />   
        </ion-content>
        
    </ion-page>

    

</template>

<script>
import { IonPage, IonHeader, IonTitle, IonContent, IonToolbar,IonBackButton,IonButtons,IonRefresher, IonRefresherContent,IonGrid} from '@ionic/vue';
import { menu } from 'ionicons/icons';

export default {
   // props: ['pageTitle','pageDefaultBack'],
    props: {

        header: {
			type: Boolean,
            default:true
		},
		pageTitle: {
			type: String
		},
		pageDefaultBack: {
			type: Function
		},
        refresh: {
            type: Boolean,
            default:false
        },
        reload:{
            type: Object
        },
        refId:{
            type: String,
            default:''
        },
        fixed:{
            type: Boolean,
            default:false
        }
    },
    setup(props) {
      const handleRefresh = (event) => { 
         props.reload.load();
        setTimeout(() => {
          // Any calls to load data go here
          event.target.complete();
        }, 2000);

       
      };

      return { handleRefresh   };
    },
    
    data(){
        return {
            menu
        }
    },
    components: {
        IonPage,
        IonHeader,
        IonTitle,
        IonContent,
        IonToolbar,
        IonBackButton,
        IonButtons,
        IonRefresher,
        IonRefresherContent,
        IonGrid
    },
    mounted() {
      
    },
    
    methods: {
       
    }
}
</script>

