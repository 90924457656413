import { createRouter, createWebHistory } from '@ionic/vue-router';
import TabsPage from '../views/TabsPage.vue'

const routes = [

  {
      path: "/login",
      name: "Login",
      component: () => import('@/views/Forms/FormLogin.vue'),
    
  },
  {
    path: "/createAccount",
    name: "Account",
    component: () => import('@/views/Forms/FormCreateAccount.vue'),
  },
  
  {
    path: "/recovery",
    name: "Recovery",
    component: () => import('@/views/Forms/FormRecovery.vue'),
  },
  {
    path: '/',
    name: "Home",
    redirect: '/tabs/map',
    
  },
  {
    path: '/tabs/',
    component: TabsPage,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        redirect: '/tabs/map'
      },
      {
        path: 'map',
        component: () => import('@/views/MapPage.vue')
      },
      {
        path: 'agenda',
        component: () => import('@/views/AgendaPage.vue')
      },
      {
        path: 'menu',
        component: () => import('@/views/MenuPage.vue')
      },
      {
        path: 'contacts',
        component: () => import('@/views/ContactsPage.vue')
      },
      {
        path: 'reports',
        component: () => import('@/views/ReportsPage.vue')
      },
      {
        path: 'profile',
        component: () => import('@/views/ProfilePage.vue')
      },
      {
        path: 'myusers',
        component: () => import('@/views/MyUserPage.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  
  let logedIn = localStorage.getItem('loggedIn');
  
  if (to.matched.some(record => record.meta.requiresAuth) ) {
      //alert(logedIn)
      if (logedIn == 'false' || logedIn == false || logedIn == null ){
         
          next({
              path: '/login',
              params: { nextUrl: to.fullPath },
              name: 'Login'
          })

      } else {
  
          /* rota livre */
          next()
              
      }
  } 
  else {
      next()
  }
})

export default router
