import 'devextreme/dist/css/dx.light.css';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import BaseLayout from './components/Layout/BaseLayout.vue';
import PrimeVue from 'primevue/config';
import store  from './storage/store';


import  * as Common   from './assets/js/common-usage.js';
import  * as FormValidation   from './assets/js/form-validations';

window.Common            =  Common;
window.FormValidation    =  FormValidation;
window.googleMapsScript  =  document.createElement('SCRIPT');

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import 'devextreme/dist/css/dx.light.css';
//theme
import "primevue/resources/themes/lara-light-indigo/theme.css";     
//core
import "primevue/resources/primevue.min.css";
//icons
import "primeicons/primeicons.css";

import './theme/main.css';

const app = createApp(App)
  .use(IonicVue)
  .use(store)
  .use(router);

app.component('base-layout',BaseLayout);
app.use(PrimeVue);


router.isReady().then(() => {
  app.mount('#app');
});


// if(document.getElementById('googleMaps') === null) {

//   window.googleMapsScript.setAttribute(
//       "src",`https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_API_KEY}&libraries=places`
//   );
//   window.googleMapsScript.setAttribute('id','googleMaps');
//   window.googleMapsScript.setAttribute('defer','');
//   window.googleMapsScript.setAttribute('async','');

//   document.head.appendChild(window.googleMapsScript);
  
// }