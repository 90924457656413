import Api from "./axios";

const state = () => ({
    //abrigo todos os states aqui  // vamos avaliar para ver se funciona bem essa ideia
    data: {}
})

const checkError = (err,resolve) => {
   
    if( Api.defaults.router.currentRoute._value.name != 'Login' && (err.response.data.error == 'Unauthorized' || err.response.data.message == "Unauthenticated.") ){
        
        if( localStorage.getItem('keeploged') ){
            
            Api.put('/geral/login',{ password:localStorage.getItem('password'),CPF_USU:localStorage.getItem('CPF_USU')}).then(res => { 
                //console.log(res.data.access_token);
                actions.setApiHeader('setApiHeader',{"token" : res.data.access_token});
                localStorage.setItem('currentToken',res.data.access_token);
                Api.defaults.router.go();

            }).catch(err => { 
                console.log(err);
                localStorage.clear();
                Api.defaults.router.push({name:'Login'});
            })
            

        }else{
          
            localStorage.removeItem('loggedIn');
            localStorage.removeItem('currentToken');
            Api.defaults.router.push({name:'Login'});

        }

    }else{
    
        console.log(err);
        resolve({type:'error',response:err});
    
    }
}

// getters
const getters = {

    getLoadedData: (state) => (service) => {

        return new Promise((resolve) => {
            resolve(state.data[service])
        });
    }

}

const actions = {
    
    resetData(){
        state.data = {}; 
    },
    setApiHeader({commit},{token}){

        //console.log(commit);
       
        return new Promise((resolve) => {
           
            Api.defaults.headers = {"Authorization" : "Bearer "+token};

            resolve({type:'success',commit});

           
        });
        
    },
    
    loadData({commit},{route,service}){
        
        return new Promise((resolve) => {
           
            Api.get(`${route}`).then(res => { 
             
                commit('setData',{data: res.data, service:service});
                resolve({type:'success',response:res});

            }).catch(err => { 

                checkError(err,resolve)
                
            });

        });

    },

    saveData({commit}, {route, payload, service}){
        
        return new Promise((resolve) => {
           
            Api.post(`${route}`,payload).then(res => { 
            
                commit('setData',{data: res.data, service: service});
                resolve({type:'success',response:res});

            }).catch(err => { 
                
                checkError(err,resolve)
            })

        })
       
    },

    updateData({ commit}, {route, payload, service}){
      
        return new Promise((resolve) => {
            
            Api.put(`${route}`,payload).then(res => { 
                //console.log(res);
                commit('setData',{data: res.data, service: service});
                resolve({type:'success',response:res});

            }).catch(err => { 
                console.log(err);
                checkError(err,resolve)
            })

        })
       
    },

    async deleteData({state}, { route }) {

        //route = await fixRoute(route);
        
        return new Promise((resolve) => {
            
            Api.delete(`${route}`)
            .then((res) => {
                resolve({ type: "success", response: res,state:state });
            })
            .catch((err) => {
                checkError(err,resolve)
            });
        });
    },
  
    clearData({ commit }, { service }) {
        commit("setData", { data: null, service: service });
    },

}

const mutations = {

    // setCredor(state,{data}){ 
    //     state.credor = data;
    // },

    setData(state,{data,service}){
       // console.log(service)
        state.data[service] = data; 
    }

}

export default {
    state,
    getters,
    actions,
    mutations
}